.body-login { 
  /* background-color:white;
  height: 100vh;
  width: 100%;
  padding-top: 150px; */
}
.login__fondo__img {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 }
.login__img {
  max-width:100%; 
  max-height:auto;
  text-align: center;
  height:220px;
  margin:auto;
  }
.login__form  {
 min-height:330px;
 height:auto;
 max-width:330px;
 margin:0 auto;
 background-color:#FFF;
 box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.login__content {
 background-color:#FFF;
 border-top:7px solid #313A3D;
 padding:7px;
}

.login__content h1 {
 text-align:center;
 color:#B30300;
 margin-top:10px;
}


.group 			  { 
position:relative; 
margin-top:45px; 
}
.group input, .login__button 				{
font-size:15px;
padding:10px 10px 10px 5px;
display:block;
width:100%;
border:none;
border-bottom:1px solid #757575;
}
.group input:focus { outline:none; transition:.2s; border-color:#B30300;}

/* LABEL
=================================== */
.group label {
color:#999; 
font-size:16px;
font-weight:normal;
position:absolute;
pointer-events:none;
left:5px;
top:10px;
transition:0.2s ease all; 
-moz-transition:0.2s ease all; 
-webkit-transition:0.2s ease all;
}

/* active state */
.group input:focus ~ label, .group input:valid ~ label 		{
top:-20px;
font-size:14px;
color:#B30300;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:100%; }
.bar:before, .bar:after 	{
content:'';
height:2px; 
width:0;
bottom:1px; 
position:absolute;
background:#B30300; 
transition:0.2s ease all; 
-moz-transition:0.2s ease all; 
-webkit-transition:0.2s ease all;
}
.bar:before {
left:50%;
}
.bar:after {
right:50%; 
}

/* active state */
.group input:focus ~ .bar:before, input:focus ~ .bar:after {
width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
position:absolute;
height:60%; 
width:100px; 
top:25%; 
left:0;
pointer-events:none;
opacity:0.5;
}

/* active state */
.group input:focus ~ .highlight {
-webkit-animation:inputHighlighter 0.3s ease;
-moz-animation:inputHighlighter 0.3s ease;
animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
from { background:#EC7063; }
to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
from { background:#EC7063; }
to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
from { background:#EC7063; }
to 	{ width:0; background:transparent; }
}


.login__options {
 width:100%;
 text-align:right;
 margin-top:30px;   
}

.login__options input {
 float:left;
}

.login__options label {
 float:left;
}

.login__options a {
 color:#B30300;
 font-size:13px;
 text-decoration:none;
}

.login__button {
 border:0;
 outline:0;
 background-color:#B30300;
 color:#FFF;
 cursor:pointer;
 border-radius:3px;
 margin-top:30px;
 text-transform: uppercase;
 font-size:14px;
 transition:.2s;
}

.login__button:hover {
 background-color:#EC7063;
}



/*Custom checkboxes from my older projects*/
/*hiding the actual checkbox and using label:before instead*/
input[type=checkbox] {
 display:none;
}

input[type=checkbox] + label:before {
  content: "";  
  display: inline-block;  
  width: 15px;  
  height: 15px;  
  vertical-align:middle;
  margin-right: 2px;  
  border:2px solid #EC7063;
  cursor:pointer;
}
input[type=checkbox]:checked + label:before {
font-family:fontawesome;
content:"\f00c";
color:white !important;
 font-size:13px;
 background-color: #EC7063; 
text-align:center;
line-height:15px;
}

input[type=checkbox]:disabled + label:before{
background-color:#ccc;
 border-color:#ccc;
}
/*form*/
.form-content{
 width:90%;
 margin:0 auto;
}

/*Bottom text*/
form p {
 padding:15px;
 color:#999;
 text-align:center;
 font-size:13px;
}

form p a {
 text-decoration:none;
 color:#EC7063;
}

/*INPUT NOT VALUE*/

.error-input {
 border: 0.1px solid red !important;
}

/*root*/
#root{
 align-items: center;
 display:grid;
}


/*add*/
.ant-col{
  max-width: 100% !important;
}
