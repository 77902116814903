  .principal-layout{
      height: 100vh;
  }

  .logo {
    float: left;
    width: 200px;
    /* height: 60px; */
    height: 100px;
    margin: 0px 0px 0px 0;
    padding: 10px;
    text-align: center;
    /* background: rgba(255, 255, 255, 0.3); */
  }
  
  .logo img {
    height: 100%;
  }