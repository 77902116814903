.header-layout {
    background: #fff;
    height: 60px;
    border: 1px solid #d8dbe0;
    width: 100%;
    z-index: 1 
}


.header-layout-user {
    float: right;
    width: 230px;
}




